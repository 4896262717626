import { CreateCampaignInput } from "@src/queries/campaign"

const baseCampaignConfig: Partial<CreateCampaignInput> = {
  schedules: "[]",
  description: "",
  subject: "",
  segment: null,

  minAge: null,
  maxAge: null,
  gender: null,
  minAppointmentDate: null,
  maxAppointmentDate: null,
  purchasedEyewearIn: null,

  templateContent: "",
  runAt: null
}

export default baseCampaignConfig
