import React from "react"
import { Router } from "@reach/router"

import SelectTemplate from "./selectTemplate/"
import SelectSegment from "./selectSegment/"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"

const CreateCampaign = (): React.ReactElement => {
  return (
    <Router>
      <SelectTemplate path={navigationRoutes[PageOptions.PICKTEMPLATE]} />
      <SelectSegment path={navigationRoutes[PageOptions.PICKSEGMENT]} />
      <SelectSegment
        path={navigationRoutes[PageOptions.DUPLICATECAMPAIGNPICKSEGMENT]}
      />
      <SelectSegment
        path={navigationRoutes[PageOptions.EDITCAMPAIGNPICKSEGMENT]}
      />
    </Router>
  )
}

export default CreateCampaign
