import React from "react"

import Layout from "@src/components/layout"
import CreateCampaignSection from "@src/sections/createCampaign"

const Create = (): React.ReactElement => {
  return (
    <Layout>
      <CreateCampaignSection />
    </Layout>
  )
}

export default Create
