import React from "react"
import tw from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { RightOutlined } from "@ant-design/icons"
import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import ErrorComponent from "@src/components/ui/error"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import {
  GET_TEMPLATES,
  Template,
  TemplateCategory,
} from "@src/queries/template"
import TemplatesComponent from "./templates"

// eslint-disable-next-line no-empty-pattern
const SelectTemplate = ({}: RouteComponentProps): React.ReactElement => {
  // page content
  const createContent = React.useContext(Localization).createCampaign

  // get templates
  const getTemplatesResp = useQuery(GET_TEMPLATES)

  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = React.useState<null | Template>(null)

  // show error page
  if (getTemplatesResp.error)
    return (
      <ErrorComponent msg="There is an error fetching data. Please refresh your page or try again later" />
    )

  // templates
  const templates: Template[] = getTemplatesResp.data
    ? getTemplatesResp.data.templates
    : []

  const globalTemplates = templates.filter(t =>
    [TemplateCategory.global, TemplateCategory.smartbook].includes(t.category)
  )

  const localTemplates = templates.filter(t =>
    [TemplateCategory.local].includes(t.category)
  )

  return (
    <div css={[tw`bg-white`]}>
      {getTemplatesResp.loading && <Loading withOverlay={true} />}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation />

        {/* templates */}
        <TemplatesComponent
          globalTemplates={globalTemplates}
          localTemplates={localTemplates}
          selectedTemplate={selectedTemplate}
          onSelectTemplate={setSelectedTemplate}
        />
      </CustomContainer>

      {/* bottom navigation */}
      <CustomContainer
        customCss={tw`flex py-4 border-t border-gray-400 justify-end w-full`}
      >
        <CustomButton
          color={ButtonColors.orange}
          customCss={tw``}
          onClick={() => {
            selectedTemplate &&
              navigate(
                createUrl(navigationRoutes[PageOptions.PICKSEGMENT], {
                  templateId: selectedTemplate.id,
                })
              )
          }}
          disabled={!selectedTemplate}
        >
          {createContent.next}
          <RightOutlined tw="ml-2" />
        </CustomButton>
      </CustomContainer>
    </div>
  )
}

export default SelectTemplate
