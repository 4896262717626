import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps, useMatch } from "@reach/router"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { useMutation, useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import { useToasts } from "react-toast-notifications"
import moment from "moment-timezone"
import {
  DatePicker,
  Form,
  Input,
  Radio,
  InputNumber,
  Select,
  TreeSelect,
} from "antd"

import CampaignNavigation from "@src/components/campaign/navigation"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomContainer from "@src/components/customContainer"
import {
  CREATE_CAMPAIGN,
  CreateCampaignInput,
  GET_CAMPAIGNS,
  Campaign,
  segmentOptions,
  genderOptions,
  Segment,
  GET_CAMPAIGN,
  audienceOptions,
  Audience,
  CampaignSchedule,
  GET_CAMPAIGN_PATIENTS_COUNT,
  UPDATE_CAMPAIGN,
} from "@src/queries/campaign"
import Loading from "@src/components/loading"
import ErrorComponent from "@src/components/ui/error"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import baseCampaignConfig from "../content/baseCampaignConfig"
import { GET_TEMPLATE, Template } from "@src/queries/template"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import InputLabel from "@src/components/ui/inputLabel"
import CustomToolTip from "@src/components/ui/customToolTip"
import TemplatePreview from "@src/components/template/preview"
import { GET_USER, User } from "@src/queries/user"
import {
  getSchedulesTreeData,
  getSelectedSchedulesForApi,
} from "@src/utils/schedules"
import { RemainingCampaigns, REMAINING_CAMPAIGNS } from "@src/queries/email"
import UsedAllCampaigns from "@src/components/usedAllCampaigns"
import { clientToServer } from "@src/utils/timezone"
import config from "@src/config"

interface SelectTemplateProps {
  templateId?: number
  campaignId?: number
}

enum FlowType {
  DuplicateCampaign = "DuplicateCampaign",
  CreateCampaign = "CreateCampaign",
  EditCampaign = "EditCampaign",
}

// current date time
const currentDateTime = clientToServer(moment().tz(config.clientTZ)).format(
  "YYYY-MM-DDTHH:mm:ss.SSS"
)

// eslint-disable-next-line no-empty-pattern
const SelectTemplate = ({
  templateId,
  campaignId,
}: SelectTemplateProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // segment
  const [selectedSegment, setSelectedSegment] = React.useState<Segment | null>(
    null
  )

  // audience
  const [
    selectedAudience,
    setSelectedAudience,
  ] = React.useState<Audience | null>(null)

  // form
  const [form] = Form.useForm()

  // find flow type
  const isDuplicateFlow = useMatch(
    navigationRoutes[PageOptions.DUPLICATECAMPAIGNPICKSEGMENT]
  )
  const isEditFlow = useMatch(
    navigationRoutes[PageOptions.EDITCAMPAIGNPICKSEGMENT]
  )
  const selectedFlow = isDuplicateFlow
    ? FlowType.DuplicateCampaign
    : isEditFlow
    ? FlowType.EditCampaign
    : FlowType.CreateCampaign

  // create campaign hook
  const [createCampaignMutation, createCampaignResp] = useMutation(
    CREATE_CAMPAIGN
  )

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // fetch campaign patients count
  const [
    getCampaignPatientsCountQuery,
    getCampaignPatientsCountResp,
  ] = useMutation(GET_CAMPAIGN_PATIENTS_COUNT)

  // get user
  const getUserResp = useQuery(GET_USER)

  // remaining campaigns that user can send
  const remainingCampaignsResp = useQuery(REMAINING_CAMPAIGNS, {
    variables: {
      runAt: currentDateTime,
    },
  })
  const remainingData: RemainingCampaigns | null =
    remainingCampaignsResp &&
    remainingCampaignsResp.data &&
    remainingCampaignsResp.data.remaining
      ? (remainingCampaignsResp.data.remaining as RemainingCampaigns)
      : null
  const hasUsedAllEmails = remainingData && remainingData.remaining < 1

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !templateId || selectedFlow !== FlowType.CreateCampaign,
  })

  // selected template
  const selectedTemplate: Template | null =
    getTemplateResp && getTemplateResp.data
      ? getTemplateResp.data.template
      : null

  // get campaign
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: { campaignId },
    skip: !campaignId || selectedFlow === FlowType.CreateCampaign,
  })

  // selected campaign
  const selectedCampaign: Campaign | null =
    getCampaignResp && getCampaignResp.data
      ? getCampaignResp.data.campaign
      : null

  // set audience based on campaign audience
  React.useEffect(() => {
    if (selectedCampaign && selectedFlow !== FlowType.CreateCampaign) {
      // set audience
      setSelectedAudience(
        JSON.parse(selectedCampaign.schedules || "[]").length > 0
          ? Audience.Specific
          : Audience.All
      )

      // set market segment
      setSelectedSegment(selectedCampaign.segment)
    }
  }, [selectedCampaign])

  // selected data
  let selectedData: {
    templateId: number | null
    templateName: string
    templateContent: string
  } = {
    templateId: null,
    templateName: "",
    templateContent: "",
  }

  // loading user
  if (getUserResp.loading) {
    return <Loading withOverlay={true} />
  }

  // api data
  const user =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)
  let footHei = 112
  if (user.hasShadowPatients) {
    footHei = 94
  }

  // segment data
  const [radioValue, setRadioValue] = React.useState<number>(
    user.hasShadowPatients ? 1 : 0
  )
  // Clear form field segment and audience scope
  React.useEffect(() => {
    if (radioValue !== 1) {
      form.setFieldsValue({ segment: "", audience: "" })
      setSelectedSegment(null)
      setSelectedAudience(null)
    }
  }, [radioValue])

  // schedules tree data
  const treeData = getSchedulesTreeData(user)
  if (selectedFlow === FlowType.CreateCampaign) {
    // create campaign flow
    // error while loading data
    if (getTemplateResp.error) {
      return (
        <ErrorComponent msg="There is an error fetching data. Please refresh your page or try again later" />
      )
    }

    // no selected template
    if (!selectedTemplate) {
      return <Loading withOverlay={true} />
    }

    selectedData = {
      templateId: selectedTemplate.id,
      templateName: selectedTemplate.name,
      templateContent: selectedTemplate.templateContent,
    }
  } else {
    // duplicate/edit campaign flow
    // error while loading data
    if (getCampaignResp.error) {
      return (
        <ErrorComponent msg="There is an error fetching data. Please refresh your page or try again later" />
      )
    }

    // no selected template
    if (!selectedCampaign) {
      return <Loading withOverlay={true} />
    }

    selectedData = {
      templateId: parseInt(selectedCampaign.selectedTemplateId),
      templateName: `${
        selectedFlow === FlowType.DuplicateCampaign ? "Duplicating" : "Editing"
      } ${selectedCampaign.name}`,
      templateContent: selectedCampaign.templateContent,
    }
  }

  // on form submit
  const onSubmit = async () => {
    try {
      // create/update campaign
      const campaignIdToUse = await createOrUpdateCampaign()

      // get patients count
      await fetchCampaignPatientsCount(campaignIdToUse)

      // navigate to next screen
      campaignIdToUse &&
        navigate(
          createUrl(navigationRoutes[PageOptions.EDITOR], {
            campaignId: campaignIdToUse,
          })
        )
    } catch (e) {
      console.log("error", e)
    }
  }

  // function to create a campaign
  const createOrUpdateCampaign = async (): Promise<number> => {
    try {
      if (!selectedData) throw new Error("Template not selected")

      // values
      const values = await form.validateFields()

      // default values
      const defaultValues =
        selectedFlow === FlowType.CreateCampaign
          ? baseCampaignConfig
          : {
              ...baseCampaignConfig,
              selectedCampaign,
            }

      // create campaign and add template to that
      const body = {
        ...defaultValues,
        ...values,
        isUsingLocalPatients: radioValue === 1 ? false : true,
        maxAppointmentDate:
          values.maxAppointmentDate &&
          moment(values.maxAppointmentDate).isValid()
            ? moment(values.maxAppointmentDate).format(
                "YYYY-MM-DDT00:00:00.000"
              )
            : null,
        minAppointmentDate:
          values.minAppointmentDate &&
          moment(values.minAppointmentDate).isValid()
            ? moment(values.minAppointmentDate).format(
                "YYYY-MM-DDT00:00:00.000"
              )
            : null,
        selectedTemplateId: `${selectedData.templateId}`,
        templateContent: selectedData.templateContent,
        schedules: JSON.stringify(
          values.audience === Audience.All || radioValue !== 1
            ? []
            : getSelectedSchedulesForApi(user, values.schedules)
        ),
      } as CreateCampaignInput

      // campaign created or updated
      let campaignIdToUse

      if (selectedFlow !== FlowType.EditCampaign) {
        // create campaign
        const createdCampaign = await createCampaignMutation({
          variables: {
            body,
          },
          refetchQueries: [
            {
              query: GET_CAMPAIGNS,
            },
          ],
        })

        campaignIdToUse = createdCampaign.data.campaign.id
      } else {
        // update campaign
        campaignIdToUse = campaignId

        // update campaign
        await updateCampaignMutation({
          variables: {
            campaignId,
            body,
          },
        })
      }

      // created campaign
      return campaignIdToUse
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // get patients count
  const fetchCampaignPatientsCount = async (campaignId: number) => {
    try {
      // create campaign
      await getCampaignPatientsCountQuery({
        variables: {
          campaignId,
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  const onChangeRadioValue = (event: any) => {
    setRadioValue(event.target.value)
  }

  const onValuesChange = () => {
    Object.keys(form.getFieldsValue(["segment", "audience"])).forEach(field => {
      if (radioValue === 1) {
        const error = form.getFieldError(field)
        if (!error.length) {
          return
        }
        // Clear error message of field
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ])
      }
    })
  }

  return (
    <div css={[tw`bg-white`]}>
      {createCampaignResp.loading ||
        updateCampaignResp.loading ||
        (getCampaignPatientsCountResp.loading && (
          <Loading withOverlay={true} />
        ))}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation />

        {/* email editing */}
        <div tw="flex mt-3 items-end justify-center">
          {/* left container */}
          <LeftContainer
            customCss={[
              css`
                border: 3px solid #ffa366;
                & .mce-content-body {
                  height: 100%;
                  width: 100%;
                  overflow: auto;
                }
                & .mce-content-body:focus {
                  outline-color: transparent !important;
                }
                .tox .tox-dialog__header {
                  background-color: #ff0000 !important;
                }
              `,
              tw`w-5/12! p-0! mr-4! items-start! border-b-0`,
            ]}
          >
            <div
              css={[
                tw`flex w-full justify-center bg-white font-bold py-1 rounded-tl-lg rounded-tr-lg`,
                css`
                  box-shadow: 0px 2px 0px 0px lightgray;
                `,
              ]}
            >
              {selectedData.templateName}
            </div>
            <div
              tw="w-full"
              css={css`
                height: 485px;
              `}
            >
              <TemplatePreview
                templateContent={selectedData.templateContent}
                scale={0.5}
                scrollable
                clickable={false}
              />
            </div>
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading="Customize this template quickly & easily"
            description="Review the template to ensure it has content sections for the type of content you want to use in your email. Choose your patient segmentation and add any notes that will help define your campaign."
            customCss={[
              tw`w-5/12! flex flex-col relative`,
              css`
                height: 517px;
                padding-bottom: 107px;
                box-shadow: 0px -1px 2px 0px gray;
                & .ant-select-selector,
                & .ant-input,
                & .ant-input-number,
                & .ant-input-affix-wrapper,
                & .ant-picker,
                & .ant-select-selector {
                  border-radius: 20px !important;
                  font-size: 0.75rem;
                }
                & .ant-row.ant-form-item {
                  margin-bottom: 0px !important;
                }
                & .ant-input-number-handler-wrap {
                  border-radius: 0px 20px 20px 0px;
                }
                & .ant-form-item-control-input-content > .ant-input-number {
                  width: 100%;
                }
              `,
            ]}
            wrapperHeadCss={[
              css`
                box-shadow: 0px 2px 0px 0px lightgray;
              `,
            ]}
            wrapperContentCss={[
              css`
                ::-webkit-scrollbar {
                  width: 8px;
                }

                ::-webkit-scrollbar-track {
                  background: #f1f1f1;
                  border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb {
                  background: #c1c1c1;
                  border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb:hover {
                  background: #a8a8a8;
                }
              `,
            ]}
          >
            <div tw="flex flex-col flex-grow border-gray-400">
              <Form
                name="segment"
                form={form}
                initialValues={
                  selectedCampaign
                    ? {
                        ...selectedCampaign,
                        maxAppointmentDate: moment(
                          selectedCampaign.maxAppointmentDate
                        ),
                        minAppointmentDate: moment(
                          selectedCampaign.minAppointmentDate
                        ),
                        name: `${selectedCampaign.name}${
                          selectedFlow === FlowType.DuplicateCampaign
                            ? " - copy"
                            : ""
                        }`,
                        audience:
                          JSON.parse(selectedCampaign.schedules || "[]")
                            .length > 0
                            ? Audience.Specific
                            : Audience.All,
                        schedules: JSON.parse(
                          selectedCampaign.schedules || "[]"
                        ).map((oneSchedule: CampaignSchedule) => {
                          return `${oneSchedule.locKey}-${oneSchedule.acctKey}`
                        }),
                      }
                    : {}
                }
                css={css`
                  max-height: ${hasUsedAllEmails ? 334 - 24 : 334}px;
                  padding-bottom: 20px;
                `}
                onChange={onValuesChange}
              >
                {/* name */}
                <div tw="flex flex-col py-3 mx-4">
                  <div tw="flex w-full flex-row items-center">
                    <InputLabel isRequired customCss={tw`w-4/12 font-bold`}>
                      Campaign Name
                    </InputLabel>
                    <div tw="flex items-center w-8/12">
                      <Form.Item
                        name="name"
                        tw="mr-2 w-full"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input campaign name!",
                          },
                          {
                            whitespace: true,
                            message: "Please input campaign name!",
                          },
                        ]}
                      >
                        <Input placeholder="Primary Vision" />
                      </Form.Item>
                      <CustomToolTip
                        title="Campaign name"
                        customCss={tw`pt-0!`}
                      />
                    </div>
                  </div>
                </div>

                {/* email subject */}
                <div tw="flex border-t border-gray-400 flex-col py-3 mx-4">
                  <div tw="flex w-full flex-row items-center">
                    <InputLabel isRequired customCss={tw`w-4/12 font-bold`}>
                      Email Subject
                    </InputLabel>
                    <div tw="flex items-center w-8/12">
                      <Form.Item
                        name="subject"
                        tw="mr-2 w-full"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input subject line!",
                          },
                          {
                            whitespace: true,
                            message: "Please input subject line!",
                          },
                        ]}
                      >
                        <Input placeholder="Appointment Reminder from Primary Vision" />
                      </Form.Item>
                      <CustomToolTip
                        title="This is email subject line"
                        customCss={tw`pt-0!`}
                      />
                    </div>
                  </div>
                </div>

                {user && user.hasShadowPatients && (
                  <>
                    {/* segment data */}
                    <div tw="flex border-t border-gray-400 flex-col py-3 mx-4">
                      <InputLabel isRequired customCss={tw`font-bold pb-4`}>
                        Choose Segment Data Input Type
                      </InputLabel>
                      <Radio.Group
                        onChange={onChangeRadioValue}
                        value={radioValue}
                      >
                        <div tw="flex w-full flex-row items-center">
                          <div tw="w-4/12 flex items-center">
                            <Radio value={1} id="segment-input" />
                            <InputLabel
                              customCss={[tw`font-bold cursor-pointer`]}
                              htmlFor="segment-input"
                            >
                              Manual Market Segment Input
                            </InputLabel>
                          </div>
                          <div tw="flex items-center w-8/12">
                            <Form.Item
                              name="segment"
                              tw="mr-2 w-full"
                              rules={[
                                {
                                  required: radioValue === 1,
                                  message: "Please select patient segment!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select..."
                                onChange={v => setSelectedSegment(v as Segment)}
                                disabled={radioValue !== 1}
                              >
                                {segmentOptions.map(oneSegment => (
                                  <Select.Option
                                    key={oneSegment.value}
                                    value={oneSegment.value}
                                  >
                                    {oneSegment.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <CustomToolTip
                              title='This tool limits patient segmentation selection to only those patients with a visit within the last 3 years. "Appointment Date Range" is the only segmentation option that will reduce the time horizon of your eblast target list.'
                              customCss={[tw`pt-0! text-sm`]}
                            />
                          </div>
                        </div>

                        {/* age segment */}
                        {selectedSegment === Segment.Age && (
                          <div tw="flex w-full flex-row items-center mt-2">
                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                From Age
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="minAge"
                                  tw="w-full"
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min={0}
                                    max={120}
                                    step={1}
                                    placeholder="20"
                                    precision={0}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div tw="w-2/12 flex justify-center text-2xs pt-3">
                              TO
                            </div>

                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                To Age
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="maxAge"
                                  tw="w-full"
                                  dependencies={["minAge"]}
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input!",
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_rule, value) {
                                        if (
                                          !value ||
                                          parseInt(getFieldValue("minAge")) <
                                            parseInt(value)
                                        ) {
                                          return Promise.resolve()
                                        }
                                        return Promise.reject(
                                          "Max age should be greater than min age!"
                                        )
                                      },
                                    }),
                                  ]}
                                >
                                  <InputNumber
                                    min={1}
                                    max={121}
                                    step={1}
                                    placeholder="60"
                                    precision={0}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* appointment date range segment */}
                        {selectedSegment === Segment.AppointmentDate && (
                          <div tw="flex w-full flex-row items-center mt-2">
                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                From Date
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="minAppointmentDate"
                                  tw="w-full"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input!",
                                    },
                                  ]}
                                >
                                  <DatePicker />
                                </Form.Item>
                              </div>
                            </div>

                            <div tw="w-2/12 flex justify-center text-2xs pt-3">
                              TO
                            </div>

                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                To Date
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="maxAppointmentDate"
                                  tw="w-full"
                                  dependencies={["minAppointmentDate"]}
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input!",
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_rule, value) {
                                        if (
                                          !value ||
                                          moment(value).isAfter(
                                            getFieldValue("minAppointmentDate")
                                          )
                                        ) {
                                          return Promise.resolve()
                                        }
                                        return Promise.reject(
                                          "Outset date should be after onset date!"
                                        )
                                      },
                                    }),
                                  ]}
                                >
                                  <DatePicker />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* gender segment */}
                        {selectedSegment === Segment.Gender && (
                          <div tw="flex w-full flex-row items-center mt-2">
                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                Select Gender
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="gender"
                                  tw="w-full"
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select Gender!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select...">
                                    {genderOptions.map(oneGender => (
                                      <Select.Option
                                        key={oneGender.value}
                                        value={oneGender.value}
                                      >
                                        {oneGender.label}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* eyewear segment */}
                        {selectedSegment === Segment.PurchasedEyewear && (
                          <div tw="flex w-full flex-row items-center mt-2">
                            <div tw="flex w-5/12 flex-col">
                              <InputLabel
                                isRequired
                                customCss={tw`w-full text-2xs! font-bold`}
                              >
                                Number of Days
                              </InputLabel>
                              <div tw="flex items-center w-full">
                                <Form.Item
                                  name="purchasedEyewearIn"
                                  tw="w-full"
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min={1}
                                    max={2190}
                                    step={5}
                                    placeholder="60"
                                    precision={0}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        )}

                        <div tw="flex w-full flex-row mt-4">
                          <div tw="w-4/12">
                            <Radio value={2} id="segment-upload-csv-file" />
                            <InputLabel
                              customCss={tw`font-bold pr-1 cursor-pointer`}
                              htmlFor="segment-upload-csv-file"
                            >
                              Upload CSV File
                            </InputLabel>
                          </div>
                          <div tw="flex items-center w-8/12 text-xs">
                            <p>
                              - You will be directed to upload your CSV file
                              after the creation of your e-Blast template.
                            </p>
                            <CustomToolTip
                              title="This is CSV file"
                              customCss={tw`pt-0! text-sm`}
                            />
                          </div>
                        </div>
                      </Radio.Group>
                    </div>

                    {/* audience scope */}
                    <div tw="flex border-t border-gray-400 flex-col py-3 mx-4">
                      <div tw="flex w-full flex-row items-center">
                        <InputLabel isRequired customCss={tw`w-4/12 font-bold`}>
                          Audience Scope
                        </InputLabel>
                        <div tw="flex items-center w-8/12">
                          <Form.Item
                            name="audience"
                            tw="mr-2 w-full"
                            rules={[
                              {
                                required: radioValue === 1,
                                message: "Please input Audience Scope!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select..."
                              onChange={v => setSelectedAudience(v as Audience)}
                              disabled={radioValue !== 1}
                            >
                              {audienceOptions.map(oneOption => (
                                <Select.Option
                                  key={oneOption.value}
                                  value={oneOption.value}
                                >
                                  {oneOption.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <CustomToolTip
                            title="Audience Scope"
                            customCss={tw`pt-0!`}
                          />
                        </div>
                      </div>

                      {/* pick schedules */}
                      {selectedAudience === Audience.Specific && (
                        <div tw="flex w-full flex-row items-center mt-2">
                          <InputLabel
                            isRequired
                            customCss={tw`w-4/12 text-2xs! font-bold`}
                          >
                            Select Location(s)
                          </InputLabel>
                          <div tw="flex items-center w-8/12">
                            <Form.Item
                              name="schedules"
                              tw="mr-2"
                              css={css`
                                width: calc(100% - 22px);
                              `}
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Please input!",
                                },
                              ]}
                            >
                              <TreeSelect
                                treeData={treeData}
                                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                placeholder="Please select..."
                                treeCheckable
                                treeDefaultExpandAll={treeData.length < 5}
                                showSearch
                                multiple
                                treeNodeFilterProp="title"
                                treeNodeLabelProp="heading"
                                maxTagCount={4}
                                listHeight={350}
                              />
                            </Form.Item>
                            <CustomToolTip
                              title="You can build a segment with all or specific providers of a location."
                              customCss={tw`pt-0!`}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* campaign description */}
                <div tw="flex border-t border-gray-400 flex-col py-3 mx-4">
                  <div tw="flex w-full flex-row items-center">
                    <InputLabel customCss={tw`w-4/12 font-bold`}>
                      Campaign Description
                    </InputLabel>
                    <div tw="flex items-center w-8/12">
                      <Form.Item name="description" tw="mr-2 w-full">
                        <Input.TextArea placeholder="Dallas Area, Turtle Creek Variable, Multiple Sizes" />
                      </Form.Item>
                      <CustomToolTip
                        title="Campaign Description"
                        customCss={tw`pt-0!`}
                      />
                    </div>
                  </div>
                </div>
              </Form>

              {/* bottom navigation */}

              <div
                tw="flex border-t-0 w-full flex-col absolute bottom-0 left-0 bg-white"
                css={css`
                  height: ${hasUsedAllEmails ? footHei : 107}px;
                `}
              >
                {user && !user.hasShadowPatients && (
                  <div tw="w-full text-xs text-center font-bold">
                    You will be directed to upload your eBlast recipients after
                    <br />
                    creating your campaign.
                  </div>
                )}
                {hasUsedAllEmails && (
                  <UsedAllCampaigns customCss="margin-bottom: 0 !important;" />
                )}

                <div
                  tw="flex py-3 justify-around w-full"
                  css={`
                    box-shadow: 0 ${hasUsedAllEmails ? 0 : -1}px 0 0 lightgray;
                    margin-top: ${hasUsedAllEmails ? 0 : 0.625}rem;
                  `}
                >
                  <CustomButton
                    color={ButtonColors.white}
                    disabled={selectedFlow === FlowType.EditCampaign}
                    onClick={() => {
                      navigate(
                        navigationRoutes[
                          selectedFlow === FlowType.CreateCampaign
                            ? PageOptions.PICKTEMPLATE
                            : PageOptions.CAMPAIGNS
                        ]
                      )
                    }}
                  >
                    <LeftOutlined tw="mr-2" />
                    BACK
                  </CustomButton>

                  <CustomButton
                    color={ButtonColors.orange}
                    onClick={() => {
                      onSubmit()
                    }}
                  >
                    NEXT - EDIT TEMPLATE
                    <RightOutlined tw="ml-2" />
                  </CustomButton>
                </div>
              </div>
            </div>
          </RightContainer>
        </div>
      </CustomContainer>
    </div>
  )
}

export default SelectTemplate
